<template>
  <ul class="check_time_line_box">
    <li class="check_time_line_item" v-for="(item, index) in data.clockdetail" :key="index">
      <div class="circle_box" :class="[!data.isholiday ? setClass(item) : '']">
      </div>
      <div class="line_box" v-if="index < data.clockdetail.length - 1"></div>
      <div class="content_box" v-if="!data.isholiday">
        <template v-if="item.is_no_clocking">
          <span></span>
          <span class="err">未打卡</span>
        </template>
        <template v-else-if="item.go_clock && (item.islate || item.islate_spring)">
          <span class="err">{{ $moment(item.work_time).format("HH:mm") }}</span>
          <span class="err">迟到</span>
        </template>
        <template v-else-if="item.off_clock && (item.isearly || item.isearly_spring)">
          <span class="err">{{ $moment(item.work_time).format("HH:mm") }}</span>
          <span class="err">早退</span>
        </template>
        <template v-else-if="item.is_field_personnel">
          <span class="app">{{ $moment(item.work_time).format("HH:mm") }}</span>
          <span class="app">app</span>
        </template>
        <template v-else>
          <span class="face" v-if="$moment(item.work_time).format('YYYY-MM-DD') != '0001-01-01'">{{
            $moment(item.work_time).format("HH:mm")
          }}</span>
        </template>
      </div>
    </li>
    <div v-if="data.isholiday" class="holiday_box">休息</div>
  </ul>
</template>
<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
export default {
  props: ['data'],
  data () {
    return {
    }
  },
  created () { },
  mounted () { },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['isAdmin', 'getOptions', 'getWayName'])
  },
  watch: {},
  methods: {
    setClass (item) {
      if (item.is_no_clocking || (item.go_clock && (item.islate || item.islate_spring)) || (item.off_clock && (item.isearly || item.isearly_spring))) {
        return 'circle_box_err'
      } else if (item.is_field_personnel) {
        return 'circle_box_app'
      } else if (this.$moment(item.work_time).format('YYYY-MM-DD') != '0001-01-01') {
        return 'circle_box_face'
      }
    }
  }
}
</script>
<style scoped lang="less">
.check_time_line_box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 14px;
  position: relative;
  overflow-y: auto;

  .check_time_line_item {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    color: #000;

    .circle_box {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      z-index: 999;
      background-color: #cccccc;
    }

    .line_box {
      position: absolute;
      left: 5px;
      top: 50%;
      bottom: 0;
      transform: translate(-50%, 0);
      width: 2px;
      height: 100%;
      background-color: #cccccc;
    }

    .content_box {
      padding: 0 10px;
      box-sizing: border-box;
      width: inherit;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      vertical-align: top;

      span {
        flex: 1;
        white-space: nowrap;
      }

      .content_date {
        height: 100%;
        display: inline-block;
        vertical-align: top;
        white-space: nowrap;
      }
    }
  }

  .holiday_box {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translate(0, -50%);
    font-size: 20px;
    color: #cccccc;
    writing-mode: vertical-lr;
  }
}

.circle_box_app {
  background-color: #5d77e6 !important;
}

.circle_box_err {
  background-color: #f55265 !important;
}

.circle_box_face {
  background-color: #1acd90 !important;
}

.app {
  color: #5d77e6;
}

.err {
  color: #f55265;
}

.face {
  color: #1acd90;
}
</style>
