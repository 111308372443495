<template>
  <!-- 考勤统计 -->
  <div style="margin-top: 20px;">
    <div class="box-card" style="text-align:left;">
      <!-- 查询表单 -->
      <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
        ref="queryFormRef">
        <el-form-item label="姓名：">
          <el-input maxlength="30" v-model="queryForm.inspector_user_name" @keyup.enter.native="onSubmit()"
            @input="(e) => (queryForm.inspector_user_name = validSpace(e))" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="考勤组：">
          <el-input maxlength="30" v-model="queryForm.attendance_group_name" @keyup.enter.native="onSubmit()"
            @input="(e) => (queryForm.attendance_group_name = validSpace(e))" placeholder="请输入考勤组"></el-input>
        </el-form-item>
        <el-form-item label="月份：">
          <el-date-picker :clearable="false" :editable="false" v-model="queryForm.date" format="yyyy 年 MM 月"
            value-format="yyyy-MM" type="month" placeholder="请选择月份" @change="onSubmit()">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
        </el-form-item>
      </el-form>
      <le-card title="考勤统计">
        <template slot="heard-right">
          <div class="right">
            <lbButton type="warning" icon="daochu" @click="openExportFileDialog"
              v-if="isShowBtn('ab878524834e42b9914b3cfe')">导出</lbButton>
          </div>
        </template>
        <!-- 主要内容 -->
        <div>
          <!-- 表格 -->
          <!-- 使用插件生产表格 -->
          <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @size-change="sizeChange"
            @current-change="currentChange">
            <template slot-scope="{row}" slot="late_minute_count">
              <el-dropdown trigger="click" v-if="row.late_minute_count > 0">
                <span class="el-dropdown-link">
                  <el-link type="primary"> {{ row.late_times - row.late_minute_count }}</el-link>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <div class="dropdown_box">
                    <div class="dropdown_item" style="border-bottom: 1px solid #c8c8c8;">
                      <span class="title">迟到总次数</span>
                      <span class="count">{{ row.late_times }}</span>
                    </div>
                    <div class="dropdown_item" style="border-bottom: 1px solid #c8c8c8;">
                      <span class="title">含弹性打卡</span>
                      <span class="count">{{ row.late_minute_count }}</span>
                    </div>
                    <div class="dropdown_item">
                      <span class="title" style="font-weight: 600;">迟到次数（合计）</span>
                      <span class="count">{{ row.late_times - row.late_minute_count }}</span>
                    </div>
                  </div>
                </el-dropdown-menu>
              </el-dropdown>
              <div v-else>{{ row.late_times }}</div>
            </template>
            <template slot-scope="{row}" slot="early_minute_count">
              <el-dropdown trigger="click" v-if="row.early_minute_count > 0">
                <span class="el-dropdown-link">
                  <el-link type="primary"> {{ row.early_leave_times - row.early_minute_count }}</el-link>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <div class="dropdown_box">
                    <div class="dropdown_item" style="border-bottom: 1px solid #c8c8c8;">
                      <span class="title">早退总次数</span>
                      <span class="count">{{ row.early_leave_times }}</span>
                    </div>
                    <div class="dropdown_item" style="border-bottom: 1px solid #c8c8c8;">
                      <span class="title">含弹性打卡</span>
                      <span class="count">{{ row.early_minute_count }}</span>
                    </div>
                    <div class="dropdown_item">
                      <span class="title" style="font-weight: 600;">早退次数（合计）</span>
                      <span class="count">{{ row.early_leave_times - row.early_minute_count }}</span>
                    </div>
                  </div>
                </el-dropdown-menu>
              </el-dropdown>
              <div v-else>{{ row.early_leave_times }}</div>
            </template>
            <template slot-scope="{row}" slot="menu">
              <lbButton type="succes" icon="chakan" hint="查看" @click="openDetails(row)"></lbButton>
            </template>
          </avue-crud> -->
          <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe>
            <el-table-column prop="inspector_user_name" label="姓名"></el-table-column>
            <el-table-column prop="attendance_group_name" label="考勤组" min-width="200"></el-table-column>
            <el-table-column prop="attendance_required" label="应出勤天数"></el-table-column>
            <el-table-column prop="actual_attendance" label="实出勤天数"></el-table-column>
            <el-table-column prop="absent" label="未出勤天数"></el-table-column>
            <el-table-column prop="should_punch_in" label="应打卡次数"></el-table-column>
            <el-table-column prop="actual_punch_in" label="实打卡次数"></el-table-column>
            <el-table-column prop="not_clocked_in" label="未打卡次数"></el-table-column>
            <el-table-column label="迟到次数">
              <template slot-scope="scope">
                <el-dropdown trigger="click" v-if="scope.row.late_minute_count > 0">
                  <span class="el-dropdown-link">
                    <el-link type="primary"> {{ scope.row.late_times - scope.row.late_minute_count }}</el-link>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <div class="dropdown_box">
                      <div class="dropdown_item" style="border-bottom: 1px solid #c8c8c8;">
                        <span class="title">迟到总次数</span>
                        <span class="count">{{ scope.row.late_times }}</span>
                      </div>
                      <div class="dropdown_item" style="border-bottom: 1px solid #c8c8c8;">
                        <span class="title">含弹性打卡</span>
                        <span class="count">{{ scope.row.late_minute_count }}</span>
                      </div>
                      <div class="dropdown_item">
                        <span class="title" style="font-weight: 600;">迟到次数（合计）</span>
                        <span class="count">{{ scope.row.late_times - scope.row.late_minute_count }}</span>
                      </div>
                    </div>
                  </el-dropdown-menu>
                </el-dropdown>
                <div v-else>{{ scope.row.late_times }}</div>
              </template>
            </el-table-column>
            <el-table-column label="早退次数">
              <template slot-scope="scope">
                <el-dropdown trigger="click" v-if="scope.row.early_minute_count > 0">
                  <span class="el-dropdown-link">
                    <el-link type="primary"> {{ scope.row.early_leave_times - scope.row.early_minute_count }}</el-link>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <div class="dropdown_box">
                      <div class="dropdown_item" style="border-bottom: 1px solid #c8c8c8;">
                        <span class="title">早退总次数</span>
                        <span class="count">{{ scope.row.early_leave_times }}</span>
                      </div>
                      <div class="dropdown_item" style="border-bottom: 1px solid #c8c8c8;">
                        <span class="title">含弹性打卡</span>
                        <span class="count">{{ scope.row.early_minute_count }}</span>
                      </div>
                      <div class="dropdown_item">
                        <span class="title" style="font-weight: 600;">早退次数（合计）</span>
                        <span class="count">{{ scope.row.early_leave_times - scope.row.early_minute_count }}</span>
                      </div>
                    </div>
                  </el-dropdown-menu>
                </el-dropdown>
                <div v-else>{{ scope.row.early_leave_times }}</div>
              </template>
            </el-table-column>
            <el-table-column label="详情" width="100">
              <template slot-scope="scope">
                <lbButton type="succes" icon="chakan" hint="查看" @click="openDetails(scope.row)"></lbButton>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </le-card>
    </div>
    <!-- 导出 -->
    <lebo-dialog title="导出" :isShow="dialogVisible" width="30%" @close="dialogClose" footerSlot>
      <el-form :model="exportFileForm" :rules="exportFileFormRules" ref="exportFileFormRef" label-width="100px"
        class="demo-ruleForm">
        <el-form-item label="日期：" prop="date">
          <div style="width:85%;display:flex;">
            <el-select popper-class="my-select" v-model="exportFileForm.date_type" placeholder="请选择">
              <el-option label="按年" :value="1"></el-option>
              <el-option label="按月" :value="2"></el-option>
            </el-select>
            <el-date-picker v-model="exportFileForm.date" v-if="exportFileForm.date_type == 2" format="yyyy 年 MM 月"
              value-format="yyyy-MM" type="month" placeholder="请选择月份" style="width:100%;margin-left:10px;">
            </el-date-picker>
            <el-date-picker v-model="exportFileForm.date" v-else-if="exportFileForm.date_type == 1" format="yyyy 年"
              value-format="yyyy" type="year" placeholder="请选择年份" style="width:100%;margin-left:10px;">
            </el-date-picker>
          </div>
        </el-form-item>
        <el-form-item label="考勤组：" prop="attendance_group_id">
          <el-select popper-class="my-select" placeholder="请选择考勤组" v-model="exportFileForm.attendance_group_id"
            style="width:85%" filterable @change="attendanceGrouChange">
            <el-option v-for="item in groupSelectList" :key="item._id" :label="item.attendance_group_name"
              :value="item._id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="人员：">
          <el-select popper-class="my-select" placeholder="请选择考勤人员" v-model="exportFileForm.personnelList" multiple
            collapse-tags filterable style="width:85%">
            <el-option v-for="item in personsList" :key="item._id" :label="item.user_name" :value="item._id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <!-- <lbButton type="goBack" icon="back" @click="dialogClose">返 回</lbButton> -->
        <lbButton type="default" fill icon="confirm" @click="fnExportAttendanceStatisticsList()" v-preventReClick>确 定
        </lbButton>
      </span>
    </lebo-dialog>
    <!-- 打卡详情 -->
    <checkingInDetailDialog :detailData="detailData" v-if="isDetailDialog" @close="isDetailDialog = false">
    </checkingInDetailDialog>
  </div>
</template>

<script>
import { getAttendanceStatisticsList, getGroupSelectList, getAttendanceStatisticsInspectorList, exportAttendanceStatisticsList } from '@/api/administrative'
import checkingInDetailDialog from './components/checkingInDetailDialog/index.vue'
export default {
  components: {
    checkingInDetailDialog
  },
  data () {
    return {
      queryForm: {
        inspector_user_name: '',
        attendance_group_name: '',
        date: this.$moment(new Date()).format('YYYY-MM'),
        PageIndex: 1,
        PageSize: 10
      },
      tableData: [],
      total: 0,
      dialogVisible: false,
      exportFileForm: {
        // 导出类型 1:按年 2:按月
        date_type: 2,
        date: '',
        attendance_group_id: '',
        personnelList: []
      },
      exportFileFormRules: {
        date: [{ required: true, message: '请选择导出日期', trigger: 'blur' }],
        attendance_group_id: [{ required: true, message: '请选择考勤组', trigger: 'blur' }]
      },
      groupSelectList: [],
      personsList: [],
      isDetailDialog: false,
      detailData: {},
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '姓名',
            prop: 'inspector_user_name'
          }, {
            label: '考勤组',
            prop: 'attendance_group_name'
          }, {
            label: '应出勤天数',
            prop: 'attendance_required'
          }, {
            label: '实出勤天数',
            prop: 'actual_attendance'
          }, {
            label: '未出勤天数',
            prop: 'absent'
          }, {
            label: '应打卡次数',
            prop: 'should_punch_in'
          }, {
            label: '实打卡次数',
            prop: 'actual_punch_in'
          }, {
            label: '未打卡次数',
            prop: 'not_clocked_in'
          }, {
            label: '迟到次数',
            prop: 'late_minute_count',
            slot: true
          }, {
            label: '早退次数',
            prop: 'early_minute_count',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  created () {
    this.fnGetAttendanceStatisticsList()
  },
  mounted () {

  },
  methods: {
    async fnGetAttendanceStatisticsList () {
      // this.queryForm.date = this.queryForm.date  ? this.$moment(new Date()).format('YYYY-MM') : this.queryForm.date;
      const res = await getAttendanceStatisticsList(this.queryForm)
      // console.log(res);
      if (res && res.Code === 200) {
        if (res.Data && res.Data.DataList.length > 0) {
          this.tableData = res.Data.DataList
          this.total = res.Data.TotalCount
        } else {
          this.tableData = []
          this.total = 0
        }
      } else {
        this.tableData = []
        this.total = 0
      }
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnGetAttendanceStatisticsList()
    },
    // 每页条数改变
    handleSizeChange (val) {
      //  // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fnGetAttendanceStatisticsList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fnGetAttendanceStatisticsList()
    // },
    // 页码改变
    handleCurrentChange (val) {
      this.queryForm.PageIndex = val
      this.fnGetAttendanceStatisticsList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0

      //  // console.log(`当前页: ${val}`);
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fnGetAttendanceStatisticsList()
    // },
    // 展示导出对话框
    openExportFileDialog () {
      this.dialogVisible = true
      this.fnGetGroupSelectList()
    },
    // 获取考勤组下拉列
    async fnGetGroupSelectList () {
      const res = await getGroupSelectList()
      console.log('获取考勤组下拉列--', res)
      if (res && res.Code === 200) {
        this.groupSelectList = res.Data
      } else {
        this.groupSelectList = []
      }
    },
    // 获取考勤组下拉框发生改变时触发
    attendanceGrouChange (id) {
      console.log(id)
      this.fnGetAttendanceStatisticsInspectorList(id)
    },
    // 获取考勤组下的人员
    async fnGetAttendanceStatisticsInspectorList (id) {
      const res = await getAttendanceStatisticsInspectorList({
        attendance_group_id: id
      })
      console.log('获取考勤组下的人员--', res)
      if (res && res.Code === 200) {
        this.exportFileForm.personnelList = []
        this.personsList = res.Data
        res.Data.forEach((item) => {
          this.exportFileForm.personnelList.push(item._id)
        })
      } else {
        this.personsList = []
        this.exportFileForm.personnelList = []
      }
    },
    fnExportAttendanceStatisticsList () {
      // 需要提前赋值时间信息备用
      const dataTIME = this.exportFileForm.date
      // 对整个表单进行校验
      this.$refs.exportFileFormRef.validate(async (valid) => {
        // 成功
        if (valid) {
          const that = this
          console.log('that.exportFileForm', that.exportFileForm)
          const res = await exportAttendanceStatisticsList(that.exportFileForm)
          // console.log('fnExportAttendanceStatisticsList---', res)
          // 处理数据格式blob
          const fileReader = new FileReader()
          fileReader.readAsText(res, 'utf-8')
          fileReader.onload = function () {
            try {
              const info = JSON.parse(fileReader.result)
              that.$msg.warning('暂无考勤记录')
            } catch (err) {
              // 解析成对象失败，说明是正常的文件流
              const blob = new Blob([res])
              // 对于<a>标签，只有 Firefox 和 Chrome（内核） 支持 download 属性
              // IE10以上支持blob但是依然不支持download
              if ('download' in document.createElement('a')) {
              // 支持a标签download的浏览器
                const link = document.createElement('a')// 创建a标签
                // link.download = `员工考勤统计表(${that.exportFileForm.date}).xlsx` // a标签添加属性
                link.download = `员工考勤统计表(${dataTIME}).xlsx` // a标签添加属性

                link.style.display = 'none'
                link.href = URL.createObjectURL(blob)
                document.body.appendChild(link)
                link.click()// 执行下载
                URL.revokeObjectURL(link.href) // 释放url
                document.body.removeChild(link)// 释放标签
              } else {
                // navigator.msSaveBlob(blob, `员工考勤统计表(${that.exportFileForm.date}).xlsx`)
                navigator.msSaveBlob(blob, `员工考勤统计表(${dataTIME}).xlsx`)
              }
            }
          }
          that.dialogVisible = false
        } else {
          return false
        }
      })
    },
    dialogClose () {
      this.dialogVisible = false
      this.$refs.exportFileFormRef.resetFields()
      this.exportFileForm.personnelList = []
      this.exportFileForm.date_type = 1
    },
    // 打开《打卡详情》对话框
    openDetails (item) {
      console.log(';openDetails-----', item)
      this.isDetailDialog = true
      this.detailData = item
    }
  }

}
</script>

<style scoped lang="less">
.dropdown_box {
  margin: 10px;
  border: 1px solid #c8c8c8;
  color: #555;

  .dropdown_item {
    .title {
      display: inline-block;
      text-align: center;
      line-height: 40px;
      width: 140px;
      height: 40px;
      border-right: 1px solid #c8c8c8;
    }

    .count {
      display: inline-block;
      text-align: center;
      line-height: 40px;
      width: 40px;
      height: 40px;
    }
  }
}</style>
