<template>
  <lebo-dialog append-to-body :title="`打卡详情【${detailData.inspector_user_name}】`" :isShow="isDetailDialog" width="70%"
    class="calendar-dialog" @close="closeHandle" footerSlot closeOnClickModal>
    <div class="hearder_box">
      <div class="left_box">
        时间: {{ detailData.date }}
      </div>
      <div class="right_box">
        <div class="right_item"><span class="circle_box info"></span> <span class="item_title">人脸打卡</span></div>
        <div class="right_item"><span class="circle_box app"></span> <span class="item_title">APP打卡</span></div>
        <div class="right_item"><span class="circle_box err"></span> <span class="item_title">异常打卡</span></div>
      </div>
    </div>
    <el-calendar v-model="value" :first-day-of-week="0" class="calendar_box" id="myCalendar">
      <!-- 这里使用的是 2.5 slot 语法，对于新项目请使用 2.6 slot 语法-->
      <template slot="dateCell" slot-scope="{date, data}">
        <!-- <span style="font-size:14px;color:red;">{{data.day}}</span> -->
        {{ data.day.split('-').slice(2).join('') }}
        <div v-for="(item, index) in clockDetailList" :key="index">
          <div class="date_detail_cell" v-if="data.type == 'current-month' && item.date == data.day">
            <CheckTimeLine :data="item" :nowDay="data.day"></CheckTimeLine>
          </div>
        </div>
        <span v-if="false">{{ date }}</span>
        <!-- <p :class="data.isSelected ? 'is-selected' : ''">
          {{ data.day.split('-').slice(1).join('-') }} {{ data.isSelected ? '✔️' : ''}}
        </p> -->
      </template>
    </el-calendar>
  </lebo-dialog>
</template>
<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import { getClockDetail } from '@/api/administrative'
import CheckTimeLine from '../checktimeline/index.vue'
import moment from 'moment'
export default {
  components: {
    CheckTimeLine
  },
  props: ['detailData'],
  data () {
    return {
      isDetailDialog: true,
      clockDetailList: [],
      value: null
    }
  },
  created () {
    this.value = new Date(this.detailData.date)
    this.fnGetClockDetail()
  },
  mounted () {
    this.$nextTick(() => {
      var myCalendar = document.getElementById('myCalendar')
      var tableRow = myCalendar.getElementsByClassName('el-calendar-table__row')
      if (tableRow[tableRow.length - 1].firstChild.classList.contains('next')) {
        tableRow[tableRow.length - 1].style.display = 'none'
      } else if (tableRow[0].lastChild.classList.contains('prev')) {
        tableRow[0].style.display = 'none'
      }
    })
  },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['isAdmin', 'getOptions', 'getWayName'])
  },
  watch: {
  },
  methods: {
    // 请求数据
    async fnGetClockDetail () {
      const res = await getClockDetail({
        sDate: this.detailData.date,
        inspector_id: this.detailData.inspector_id
      })
      console.log('this.clockDetailList', res)
      this.clockDetailList = res && res.Code === 200 ? res.Data.resultData : []
      const statisticsDetail = res && res.Code === 200 ? res.Data.statistics_detail : {}
      // 判断是否是在之前入职，是则不显示未打卡信息
      const time = moment(statisticsDetail.induction_date).subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss')
      if (this.clockDetailList.length > 0) {
        this.clockDetailList.forEach(item => {
          if (moment(item.date).isBefore(time)) {
            item.clockdetail.forEach(element => {
              element.is_no_clocking = false
            })
          }
        })
      }
    },
    closeHandle (e) {
      this.isDetailDialog = false
      this.$emit('close', e)
    }
  }
}
</script>
<style scoped lang="less">
.calendar-dialog {
  /deep/ .el-dialog {
    margin-top: 5vh !important;
  }
}

.hearder_box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .right_box {
    display: flex;

    .right_item {
      display: flex;
      margin-left: 20px;

      .circle_box {
        display: inline-block;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        margin-right: 5px;
      }
    }
  }
}

.el-calendar.calendar_box {
  /deep/ .el-calendar__header {
    display: none;
  }

  /deep/.el-calendar__body {
    padding-bottom: 0px;
  }

  /deep/ .el-calendar-table {
    th {
      border: 1px solid #ebeef5;
    }

    .el-calendar-day:hover {
      background-color: transparent;
    }

    td.is-selected {
      background-color: transparent;
    }

    td.next {
      pointer-events: none;
    }

    td.prev {
      pointer-events: none;
    }

  }

  /deep/ .current,
  /deep/ .next,
  /deep/ .prev {
    width: 150px;
    height: 100px;
    background-color: none;

    .el-calendar-day {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 60px;
      font-weight: 700;
      color: rgba(169,169,169,.2);
      height: 100px;
    }
  }

  /deep/ .current {
    position: relative;

    .date_detail_cell {
      font-size: 12px;
      color: #000;
      position: absolute;
      top: 10px;
      left: 10px;
      right: 10px;
      bottom: 10px;
    }
  }
}

.info {
  // background-color: #cccccc;
  background-color: #1acd90;
}

.app {
  background-color: #5d77e6;
}

.err {
  background-color: #f55265;
}</style>
